// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resumoPedido_section__dVm\\+U{
    width: 50%;
    height: 100%;
    background-color: #F2F4F8;
    margin-top: 60px;
    margin-bottom: 80px;
    border-radius: 10px;
    min-width: 520px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.resumoPedido_page__VCKdp{
    background-color: #EBE9E2    ;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.resumoPedido_enquadramento__CpXK5{
    margin-bottom: 10%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 40px;
    text-align: left;
}

`, "",{"version":3,"sources":["webpack://./src/paginas/resumoPedido/resumoPedido.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,iDAAiD;AACrD;;AAEA;IACI,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".section{\n    width: 50%;\n    height: 100%;\n    background-color: #F2F4F8;\n    margin-top: 60px;\n    margin-bottom: 80px;\n    border-radius: 10px;\n    min-width: 520px;\n    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;\n}\n\n.page{\n    background-color: #EBE9E2    ;\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    align-items: center;\n}\n\n.enquadramento{\n    margin-bottom: 10%;\n    margin-left: 10%;\n    margin-right: 10%;\n    margin-top: 40px;\n    text-align: left;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `resumoPedido_section__dVm+U`,
	"page": `resumoPedido_page__VCKdp`,
	"enquadramento": `resumoPedido_enquadramento__CpXK5`
};
export default ___CSS_LOADER_EXPORT___;
