// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.historico_section__6iYUK{
    font-family: 'Monstserrat', sans-serif;
    background-color: #F2F4F8;   
    width: 55%;
    height: 100%;
    margin-top: 40px;
    margin-bottom: 100px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    padding-bottom: 20px;
    min-width: 635px;
}

.historico_page__YWJg7{
    background-color: #EBE9E2;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.historico_refresh__7XW5J {
    border: 0;
    outline: 0;
    background: none;
    font-size: 24px;
    cursor: pointer;
    transition: 200ms linear
}

.historico_refresh__7XW5J:hover {
    transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/paginas/historico/historico.module.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,yBAAyB;IACzB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,iDAAiD;IACjD,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf;AACJ;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".section{\n    font-family: 'Monstserrat', sans-serif;\n    background-color: #F2F4F8;   \n    width: 55%;\n    height: 100%;\n    margin-top: 40px;\n    margin-bottom: 100px;\n    border-radius: 20px;\n    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;\n    padding-bottom: 20px;\n    min-width: 635px;\n}\n\n.page{\n    background-color: #EBE9E2;\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    align-items: center;\n}\n\n.refresh {\n    border: 0;\n    outline: 0;\n    background: none;\n    font-size: 24px;\n    cursor: pointer;\n    transition: 200ms linear\n}\n\n.refresh:hover {\n    transform: scale(1.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `historico_section__6iYUK`,
	"page": `historico_page__YWJg7`,
	"refresh": `historico_refresh__7XW5J`
};
export default ___CSS_LOADER_EXPORT___;
