// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meuModal_box__ph6Kz{
    width: 513px;
    height: 472px;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400;
    transform: translate(-50%, -50%);
    background-color: #F2F4F8;
    border-radius: 10px;
    border: 2px solid #9E184B;
    background: #F2F4F8;
    padding: 10px;
}

.meuModal_box__ph6Kz p{
    font-size: 20px;
    margin: 35px
}

.meuModal_box__ph6Kz h1{
color: #000;
text-align: center;
font-size: 28px;
font-style: normal;
font-weight: 700;

}

.meuModal_box__ph6Kz h2{
    color: #543A20;
margin-left: 35px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.meuModal_inputModal__nyhHq{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    /* background-color: #000; */
}

.meuModal_modalButton__XnytX{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 72px;
}

`, "",{"version":3,"sources":["webpack://./src/componentes/meuModal.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,QAAQ;IACR,SAAS;IACT,UAAU;IACV,gCAAgC;IAChC,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf;AACJ;;AAEA;AACA,WAAW;AACX,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;;AAEhB;;AAEA;IACI,cAAc;AAClB,iBAAiB;AACjB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".box{\n    width: 513px;\n    height: 472px;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    width: 400;\n    transform: translate(-50%, -50%);\n    background-color: #F2F4F8;\n    border-radius: 10px;\n    border: 2px solid #9E184B;\n    background: #F2F4F8;\n    padding: 10px;\n}\n\n.box p{\n    font-size: 20px;\n    margin: 35px\n}\n\n.box h1{\ncolor: #000;\ntext-align: center;\nfont-size: 28px;\nfont-style: normal;\nfont-weight: 700;\n\n}\n\n.box h2{\n    color: #543A20;\nmargin-left: 35px;\nfont-size: 20px;\nfont-style: normal;\nfont-weight: 400;\nline-height: normal;\n}\n\n.inputModal{\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 40px;\n    width: 100%;\n    /* background-color: #000; */\n}\n\n.modalButton{\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    margin-right: 72px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `meuModal_box__ph6Kz`,
	"inputModal": `meuModal_inputModal__nyhHq`,
	"modalButton": `meuModal_modalButton__XnytX`
};
export default ___CSS_LOADER_EXPORT___;
