// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.senhaContem_pai__1SlCO{
width: 200px;

}

.senhaContem_check__C6\\+29{
  display: flex;
  flex-direction: row;
  align-items: center ;
  text-align: center;
  color:#0FA958  ;
  width: 100%;
  height: 20px;
  margin-left:10%;
 
}

.senhaContem_circulo__hF1xz{
  display: flex;
  align-items: center ;
  text-align: center;
  flex-direction: row;
  color: #9E184B ;
  width: 100%;
  height: 20px;
  margin-left:10%;
 
}

.senhaContem_circulo__hF1xz span{
  font-size: 8px;
  width: 25px;
 
}

.senhaContem_check__C6\\+29 span{
  font-size: 20px;
  width: 26px;
}

.senhaContem_circulo__hF1xz p{
  font-size: 15px;
  color: #9E184B ;
  margin: 0px;
}

.senhaContem_check__C6\\+29 p {
  font-size: 15px;
  color:#0FA958  ;
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/utils/senhaContem.module.css"],"names":[],"mappings":"AAAA;AACA,YAAY;;AAEZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,eAAe;;AAEjB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,eAAe;;AAEjB;;AAEA;EACE,cAAc;EACd,WAAW;;AAEb;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb","sourcesContent":[".pai{\nwidth: 200px;\n\n}\n\n.check{\n  display: flex;\n  flex-direction: row;\n  align-items: center ;\n  text-align: center;\n  color:#0FA958  ;\n  width: 100%;\n  height: 20px;\n  margin-left:10%;\n \n}\n\n.circulo{\n  display: flex;\n  align-items: center ;\n  text-align: center;\n  flex-direction: row;\n  color: #9E184B ;\n  width: 100%;\n  height: 20px;\n  margin-left:10%;\n \n}\n\n.circulo span{\n  font-size: 8px;\n  width: 25px;\n \n}\n\n.check span{\n  font-size: 20px;\n  width: 26px;\n}\n\n.circulo p{\n  font-size: 15px;\n  color: #9E184B ;\n  margin: 0px;\n}\n\n.check p {\n  font-size: 15px;\n  color:#0FA958  ;\n  margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pai": `senhaContem_pai__1SlCO`,
	"check": `senhaContem_check__C6+29`,
	"circulo": `senhaContem_circulo__hF1xz`
};
export default ___CSS_LOADER_EXPORT___;
