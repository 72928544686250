// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabelaHistorico_table__R5w8C {
    border-collapse: collapse;
    width: 100%;
  }

.tabelaHistorico_table__R5w8C tr{
    align-items: right;
    height: 50px;
    border: 1px solid #F2F4F8;

}


.tabelaHistorico_table__R5w8C tr:nth-child(even){
    background-color: #9E184B33;
    
} 

.tabelaHistorico_table__R5w8C tr:nth-child(odd){
    background: #9E184B1F;

}

.tabelaHistorico_clickable__GvEcW {
    color: #9E184B;
    cursor: pointer;
    font-weight: 450;
    transition: 150ms linear;
}

.tabelaHistorico_clickable__GvEcW:hover {
    transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/paginas/historico/tabelaHistorico.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;EACb;;AAEF;IACI,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;;AAE7B;;;AAGA;IACI,2BAA2B;;AAE/B;;AAEA;IACI,qBAAqB;;AAEzB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".table {\n    border-collapse: collapse;\n    width: 100%;\n  }\n\n.table tr{\n    align-items: right;\n    height: 50px;\n    border: 1px solid #F2F4F8;\n\n}\n\n\n.table tr:nth-child(even){\n    background-color: #9E184B33;\n    \n} \n\n.table tr:nth-child(odd){\n    background: #9E184B1F;\n\n}\n\n.clickable {\n    color: #9E184B;\n    cursor: pointer;\n    font-weight: 450;\n    transition: 150ms linear;\n}\n\n.clickable:hover {\n    transform: scale(1.1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `tabelaHistorico_table__R5w8C`,
	"clickable": `tabelaHistorico_clickable__GvEcW`
};
export default ___CSS_LOADER_EXPORT___;
