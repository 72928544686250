// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_checkbox__5co1t {
    width: 21px;
    min-width: 21px;
    height: 21px;
    min-height: 21px;
    margin: 0;
    margin-right: 10px;
    margin-left: 5px;
}

.checkbox_clickable__3yFcL {
    cursor: pointer;
}

.checkbox_clickable__3yFcL:hover {
    transform: scale(1.02);
}`, "",{"version":3,"sources":["webpack://./src/componentes/checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,SAAS;IACT,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".checkbox {\n    width: 21px;\n    min-width: 21px;\n    height: 21px;\n    min-height: 21px;\n    margin: 0;\n    margin-right: 10px;\n    margin-left: 5px;\n}\n\n.clickable {\n    cursor: pointer;\n}\n\n.clickable:hover {\n    transform: scale(1.02);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `checkbox_checkbox__5co1t`,
	"clickable": `checkbox_clickable__3yFcL`
};
export default ___CSS_LOADER_EXPORT___;
