// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Monstserrat';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

.boasVindas_section__0vsxX{
    font-family: 'Monstserrat', sans-serif;
    margin-top: 40px;
    width: 51%;
    height: 130px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    background-color:  #F2F4F8;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;;
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    min-width: 700px;

}

.boasVindas_section__0vsxX p{
    font-size: 100%;
}

.boasVindas_section__0vsxX img{
    width: 200px;
    height: 205px;
    margin-left: -20px;
    left: 0;
}

.boasVindas_boasVindas__oL2vF{
    display: flex;
    flex-direction: row;
    background-color: #EBE9E2;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/componentes/boasVindas.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,+DAAuE;AAC3E;;AAEA;IACI,sCAAsC;IACtC,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;IACjB,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,8BAA8B;IAC9B,iCAAiC;IACjC,6BAA6B;IAC7B,gCAAgC;IAChC,iDAAiD;IACjD,gBAAgB;;AAEpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,OAAO;AACX;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;AAC3B","sourcesContent":["@font-face {\n    font-family: 'Monstserrat';\n    src: url('../font/Montserrat-VariableFont_wght.ttf') format('truetype');\n}\n\n.section{\n    font-family: 'Monstserrat', sans-serif;\n    margin-top: 40px;\n    width: 51%;\n    height: 130px;\n    padding-right: 20px;\n    padding-bottom: 15px;\n    padding-top: 15px;\n    background-color:  #F2F4F8;\n    display: flex;\n    flex-direction: row;\n    text-align: left;\n    align-items: center;\n    border-top-right-radius: 100px;\n    border-bottom-right-radius: 100px;;\n    border-top-left-radius: 500px;\n    border-bottom-left-radius: 500px;\n    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;\n    min-width: 700px;\n\n}\n\n.section p{\n    font-size: 100%;\n}\n\n.section img{\n    width: 200px;\n    height: 205px;\n    margin-left: -20px;\n    left: 0;\n}\n\n.boasVindas{\n    display: flex;\n    flex-direction: row;\n    background-color: #EBE9E2;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `boasVindas_section__0vsxX`,
	"boasVindas": `boasVindas_boasVindas__oL2vF`
};
export default ___CSS_LOADER_EXPORT___;
