// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__3O3kM{
    background-color: #9E184B ;
    width: 100%;
    height: 136px;
    top: 0;
    border-bottom: 13px solid #543A20;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.header_header__3O3kM ul{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 60px;
    
}

.header_header__3O3kM img{
    width: 146px;
    height: 47px;
    margin-left: 60px ;
}

.header_header__3O3kM li{
    margin-left: 60px;
    color:white
}

.header_clickable__D9vHt {
    cursor: pointer;
    transition: 150ms linear;
}

.header_clickable__D9vHt:hover {
    transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/componentes/header.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,WAAW;IACX,aAAa;IACb,MAAM;IACN,iCAAiC;IACjC,aAAa;IACb,mBAAmB;IACnB,mBAAmB;;AAEvB;AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;;AAEtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB;AACJ;;AAEA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".header{\n    background-color: #9E184B ;\n    width: 100%;\n    height: 136px;\n    top: 0;\n    border-bottom: 13px solid #543A20;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    \n}\n.header ul{\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    margin-right: 60px;\n    \n}\n\n.header img{\n    width: 146px;\n    height: 47px;\n    margin-left: 60px ;\n}\n\n.header li{\n    margin-left: 60px;\n    color:white\n}\n\n.clickable {\n    cursor: pointer;\n    transition: 150ms linear;\n}\n\n.clickable:hover {\n    transform: scale(1.05);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__3O3kM`,
	"clickable": `header_clickable__D9vHt`
};
export default ___CSS_LOADER_EXPORT___;
