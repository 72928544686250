// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./font/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family:  'Monstserrat';
      src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype')
   
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Monstserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
input{
  outline: 0;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;MACvB;;AAEN;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;;AAEpC;AACA;EACE,UAAU;AACZ;;AAEA;;;GAGG","sourcesContent":["@font-face {\n  font-family:  'Monstserrat';\n      src:url('./font/Montserrat-VariableFont_wght.ttf') format('truetype')\n   \n}\n\nbody {\n  overflow-x: hidden;\n  margin: 0;\n  font-family: 'Monstserrat', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n}\ninput{\n  outline: 0;\n}\n\n/* code {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
