// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family:  'Monstserrat';
        src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype')
     
  }
.button_button__lguzX{
    font-weight: 700;
    border-radius: 100px;
    width: 270px;
    height: 45px;
    padding: 10px 15px;
    border: 2px solid  #9E184B;
    font-family: 'Monstserrat', sans-serif;
    width: 174px;
    font-size: 17px;
    cursor: pointer;
    transition: 200ms linear;
}

.button_button__lguzX:hover {
    transform: scale(1.02);
}
`, "",{"version":3,"sources":["webpack://./src/componentes/button.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;QACvB;;EAEN;AACF;IACI,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,sCAAsC;IACtC,YAAY;IACZ,eAAe;IACf,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["@font-face {\n    font-family:  'Monstserrat';\n        src:url('../font/Montserrat-VariableFont_wght.ttf') format('truetype')\n     \n  }\n.button{\n    font-weight: 700;\n    border-radius: 100px;\n    width: 270px;\n    height: 45px;\n    padding: 10px 15px;\n    border: 2px solid  #9E184B;\n    font-family: 'Monstserrat', sans-serif;\n    width: 174px;\n    font-size: 17px;\n    cursor: pointer;\n    transition: 200ms linear;\n}\n\n.button:hover {\n    transform: scale(1.02);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__lguzX`
};
export default ___CSS_LOADER_EXPORT___;
