// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family:  'Monstserrat';
        src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype')
     
  }

.inputForm_inputForm__EVSk8{
    width: 100%;
    height: 100%;
    border-radius: 100px;
    font-family: 'Monstserrat', sans-serif;
    border: 1px solid  #9E184B;
    background-color: #F2F4F8;
    padding-left: 10px;
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/componentes/inputForm.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;QACvB;;EAEN;;AAEF;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,sCAAsC;IACtC,0BAA0B;IAC1B,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["@font-face {\n    font-family:  'Monstserrat';\n        src:url('../font/Montserrat-VariableFont_wght.ttf') format('truetype')\n     \n  }\n\n.inputForm{\n    width: 100%;\n    height: 100%;\n    border-radius: 100px;\n    font-family: 'Monstserrat', sans-serif;\n    border: 1px solid  #9E184B;\n    background-color: #F2F4F8;\n    padding-left: 10px;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputForm": `inputForm_inputForm__EVSk8`
};
export default ___CSS_LOADER_EXPORT___;
