import styles from "./button.module.css";

function Button({ text, onClick, cor, corFont, width, isSubmit}) {
  return (
    <>
      <button
        style={{ backgroundColor: cor, color: corFont, width: width && width }}
        className={`${styles.button}`}
        onClick={onClick}
        type={isSubmit ? "submit" : "button"}
      >
        {text}
      </button>
    </>
  );
}

export default Button;
