// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family:  'Monstserrat';
        src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype')
     
  }

.InputSenha_input__qahor{
    width: 100%;
    display: flex;
    height: 26px;
    padding: 10px 15px;
    font-size: 17px;
    font-family: 'Monstserrat', sans-serif;
    background: white;
    border-radius: 100px;
    border: 2px solid #9E184B ;
    align-items: center;
}


.InputSenha_input__qahor input{
    background: white;
    border: none;
    width: 85%; 
    font-size: 17px;
    font-family: 'Monstserrat', sans-serif;
}

.InputSenha_clickable__sWkrP {
    cursor: pointer;
    transition: 150ms linear;
}

.InputSenha_clickable__sWkrP:hover {
    transform: scale(1.07);
}`, "",{"version":3,"sources":["webpack://./src/componentes/InputSenha.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;QACvB;;EAEN;;AAEF;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,iBAAiB;IACjB,oBAAoB;IACpB,0BAA0B;IAC1B,mBAAmB;AACvB;;;AAGA;IACI,iBAAiB;IACjB,YAAY;IACZ,UAAU;IACV,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["@font-face {\n    font-family:  'Monstserrat';\n        src:url('../font/Montserrat-VariableFont_wght.ttf') format('truetype')\n     \n  }\n\n.input{\n    width: 100%;\n    display: flex;\n    height: 26px;\n    padding: 10px 15px;\n    font-size: 17px;\n    font-family: 'Monstserrat', sans-serif;\n    background: white;\n    border-radius: 100px;\n    border: 2px solid #9E184B ;\n    align-items: center;\n}\n\n\n.input input{\n    background: white;\n    border: none;\n    width: 85%; \n    font-size: 17px;\n    font-family: 'Monstserrat', sans-serif;\n}\n\n.clickable {\n    cursor: pointer;\n    transition: 150ms linear;\n}\n\n.clickable:hover {\n    transform: scale(1.07);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `InputSenha_input__qahor`,
	"clickable": `InputSenha_clickable__sWkrP`
};
export default ___CSS_LOADER_EXPORT___;
