// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family:  'Monstserrat';
        src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype')
     
  }

.input_input__3ANvT{
    width: 100%;
    height: 26px;
    padding: 10px 15px;
    font-size: 17px;
    font-family: 'Monstserrat', sans-serif;
    border: none;
    background: white;
    border-radius: 100px;
    border: 2px solid #9E184B ;
    align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/componentes/input.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;QACvB;;EAEN;;AAEF;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,0BAA0B;IAC1B,mBAAmB;AACvB","sourcesContent":["@font-face {\n    font-family:  'Monstserrat';\n        src:url('../font/Montserrat-VariableFont_wght.ttf') format('truetype')\n     \n  }\n\n.input{\n    width: 100%;\n    height: 26px;\n    padding: 10px 15px;\n    font-size: 17px;\n    font-family: 'Monstserrat', sans-serif;\n    border: none;\n    background: white;\n    border-radius: 100px;\n    border: 2px solid #9E184B ;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `input_input__3ANvT`
};
export default ___CSS_LOADER_EXPORT___;
