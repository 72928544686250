// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio_radio__BoAa1 {
    width: 21px;
    min-width: 21px;
    height: 21px;
    min-height: 21px;
    margin: 0;
    margin-right: 10px;
    margin-left: 5px;
}
.radio_clickable__FkMxR {
    cursor: pointer;
    transition: 220ms linear;
}

.radio_clickable__FkMxR:hover {
    transform: scale(1.02);
}`, "",{"version":3,"sources":["webpack://./src/componentes/radio.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,SAAS;IACT,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".radio {\n    width: 21px;\n    min-width: 21px;\n    height: 21px;\n    min-height: 21px;\n    margin: 0;\n    margin-right: 10px;\n    margin-left: 5px;\n}\n.clickable {\n    cursor: pointer;\n    transition: 220ms linear;\n}\n\n.clickable:hover {\n    transform: scale(1.02);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": `radio_radio__BoAa1`,
	"clickable": `radio_clickable__FkMxR`
};
export default ___CSS_LOADER_EXPORT___;
