// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConexaoPerdida_boxConexaoPerdida__dQHhk {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.ConexaoPerdida_boxConexaoPerdida__dQHhk span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px
}

.ConexaoPerdida_boxConexaoPerdida__dQHhk button {
    border: 0;
    outline: 0;
    background: none;
    font-size: 36px;
    color: #9E184B;
    cursor: pointer;
    transition: 200ms linear
}
.ConexaoPerdida_boxConexaoPerdida__dQHhk button:hover {
    transform: scale(1.2);
}`, "",{"version":3,"sources":["webpack://./src/paginas/formularios/ConexaoPerdida.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB;AACJ;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB;AACJ;;AAEA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,eAAe;IACf;AACJ;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".boxConexaoPerdida {\n    height: 500px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center\n}\n\n.boxConexaoPerdida span {\n    font-size: 22px;\n    font-weight: bold;\n    margin-bottom: 30px\n}\n\n.boxConexaoPerdida button {\n    border: 0;\n    outline: 0;\n    background: none;\n    font-size: 36px;\n    color: #9E184B;\n    cursor: pointer;\n    transition: 200ms linear\n}\n.boxConexaoPerdida button:hover {\n    transform: scale(1.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxConexaoPerdida": `ConexaoPerdida_boxConexaoPerdida__dQHhk`
};
export default ___CSS_LOADER_EXPORT___;
