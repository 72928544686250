import { useEffect, useState } from "react";
import TipoYouCoffee from "../../componentes/TipoYouCoffee";
import { tamanhoCoffee } from "../../services/api";
import styles from "./FormularioAlimentos.module.css";
import RestricaoAlimentar from "../../componentes/RestricaoAlimentar";
import Observacoes from "../../componentes/Observacoes";
import Button from "../../componentes/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDadosAlimentos } from "../../redux/pedidos/action";
import Loading from "../../componentes/Loading";
import { toastPerdaDadosRedux, toastWarn } from "../../utils/Toast";
import ConexaoPerdida from "./ConexaoPerdida";
function FormularioPadrao() {
  const [tamanhoCoffees, setTamanhoCoffees] = useState([]);
  const [dadosObservacoes, setDadosObservacoes] = useState([]);
  const [restricoesAlimentar, setRestricoesAlimentar] = useState("");
  const [coffeeSelecionado, setCoffeeSelecionado] = useState(null);
  const [quais, setQuais] = useState(false);
  const [loading, setLoading] = useState(false);
  const [erroView, setErroView] = useState(false)
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dadosTotalPedidos = useSelector((state) => state.dadosTotalPedidos);

  async function dadosBack() {
    setLoading(true);
    const existeDadosAnteriores = verificarDadosAnteriores()
    if(existeDadosAnteriores) {  
      const response = await tamanhoCoffee("PADRAO");
      setTamanhoCoffees(response?.status === 200 ? response?.data : null);
      if(!tamanhoCoffees) getDadosAlimentosRedux()
    } else {
      setLoading(false);
      toastPerdaDadosRedux()
      navigate("/formulario-dados-gerais")
    }
  }

  useEffect(() => {
    dadosBack();
  }, []);

  function voltarPagina() {
    const informacoesAlimento = {
      observacaoAlimento:
        dadosObservacoes?.length > 0 ? dadosObservacoes : null,
        restricaoAlimentar: restricoesAlimentar?.length > 0 ? restricoesAlimentar : null,
    };
    const dadosPedidoAlimento = {
      tamanhoYouCoffee: coffeeSelecionado,
      informacoesAlimento: informacoesAlimento,
    };
    dispatch(setDadosAlimentos(dadosPedidoAlimento));
    navigate("/formulario-dados-gerais");
  }

  useEffect(() => {
    if(JSON.stringify(tamanhoCoffees) !== "[]") getDadosAlimentosRedux()
  }, [tamanhoCoffees])

  function getDadosAlimentosRedux() {
    if (
      JSON.stringify(dadosTotalPedidos?.dadosPedidosAlimentos) !== "{}" &&
      tamanhoCoffees
    ) {
      const dados = dadosTotalPedidos?.dadosPedidosAlimentos;

      const tamanhoCoffee = dados?.tamanhoYouCoffee;

      const infoAlimento = dados?.informacoesAlimento;

      if (tamanhoCoffees && tamanhoCoffees?.length > 0 && tamanhoCoffee) {
        setCoffeeSelecionado({
          idTamanhoYouCoffee: parseInt(tamanhoCoffee?.idTamanhoYouCoffee),
          nomeTamanhoYouCoffee: tamanhoCoffee?.nomeTamanhoYouCoffee,
        });
      }

      if (
        infoAlimento?.restricaoAlimentar &&
        infoAlimento?.restricaoAlimentar !== ""
      ) {
        setRestricoesAlimentar(infoAlimento?.restricaoAlimentar);
        setQuais(true);
      } else {
        setQuais(false);
        setRestricoesAlimentar("");
      }
      setDadosObservacoes(infoAlimento?.observacaoAlimento);
    }
    setLoading(false)
  }

  function enviarParaResumo(e) {
    setLoading(true)
    e.preventDefault();
    let erros = []
    if (!coffeeSelecionado?.idTamanhoYouCoffee) erros.push("Selecione o tamanho do YouCoffee");
    if (quais && (restricoesAlimentar === "" || !restricoesAlimentar)) erros.push("Preecha o campo 'quais' das restrições alimentares");
    if(erros.length === 0) {
      const informacoesAlimento = {
        observacaoAlimento:
          dadosObservacoes?.length > 0 ? dadosObservacoes : null,
          restricaoAlimentar: restricoesAlimentar.length > 0 ? restricoesAlimentar : null,
      };
      const dadosPedidoAlimento = {
        tamanhoYouCoffee: coffeeSelecionado,
        informacoesAlimento: informacoesAlimento,
      };
      dispatch(setDadosAlimentos(dadosPedidoAlimento));
      navigate("/resumo-do-pedido");
    } else {
      setErroView(true);
      if (erros.length === 1) {
        toastWarn(erros[0]);
      } else {
        const singular = erros.length - 1 === 1;
        toastWarn(
          `${erros[0]}, há ${
            singular ? "outro erro" : `outros ${erros.length - 1} erros`
          }, verifique-o${!singular ? "s" : ""} posteriormente`
        );
      }
    }

    setLoading(false)
  }

  function restricoesAlimentares(value) {
    if (value === "SIM") {
      setQuais(true);
    } else {
      setQuais(false);
      setRestricoesAlimentar("");
    }
  }

  const alterarCoffeeSelecionado = (
    idTamanhoYouCoffee,
    descricaoTamanhoYouCoffee
  ) => {
    setCoffeeSelecionado({
      idTamanhoYouCoffee: idTamanhoYouCoffee,
      nomeTamanhoYouCoffee: descricaoTamanhoYouCoffee,
    });
  };

  function verificarDadosAnteriores() {
    if (
      JSON.stringify(dadosTotalPedidos?.dadosPedidos) === "{}" ||
      !dadosTotalPedidos?.dadosPedidos
    )
      return false;
    else return true;
  }

  return (
    <div>
      <Loading open={loading} />

      <div className={`${styles.page}`}>
        <section className={`${styles.section}`}>
          {tamanhoCoffees ?
            <div className={`${styles.enquadramento}`}>
              <h2>Meu YouCoffee</h2>
              <TipoYouCoffee
                erroView={erroView}
                tamanhoCoffees={tamanhoCoffees}
                handleOnchange={alterarCoffeeSelecionado}
                coffeeSelecionado={coffeeSelecionado}
              />
              <RestricaoAlimentar
                quais={quais}
                onChange={(e) => restricoesAlimentares(e.target.value)}
                onChangeSet={(e) => setRestricoesAlimentar(e.target.value)}
                restricoesAlimentar={restricoesAlimentar}
                erroView={erroView}
              />
              <h1>Observações:</h1>
              <div style={{ marginBottom: "60px" }}>
                <Observacoes
                  value={dadosObservacoes}
                  onChange={(e) => setDadosObservacoes(e.target.value)}
                />
              </div>
              {tamanhoCoffees && (
                <div className={`${styles.voltarProximo}`}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      voltarPagina();
                    }}
                    cor={"#F2F4F8"}
                    corFont={"#9E184B"}
                    text={"Voltar"}
                  />
                  <Button
                    onClick={(e) => {
                      enviarParaResumo(e);
                    }}
                    cor={"#9E184B"}
                    corFont={"#EBE9E2"}
                    text={"Próximo"}
                  />
                </div>
              )}
            </div>
          : <ConexaoPerdida handleOnClick={(e) => {e.preventDefault(); dadosBack()}}/>
         }
        </section>
      </div>
    </div>
  );
}

export default FormularioPadrao;
